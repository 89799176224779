module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_K5es7S7V5c7jqToGYV2REgL1Mg0yCemUA8DZViCzbEd","head":true,"isEnabledDevMode":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.madaytalentpartners.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Oswald:200,300,400,500,600,700","sans-serif"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Maday Talent Partners","short_name":"Maday Recruiting","start_url":".","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/mtp-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"16dde5f7a7135ac5902b341cb871b5ad"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
